@import '~bootstrap/scss/bootstrap.scss';
@import '~font-awesome/scss/font-awesome.scss';
@import '~react-loader-spinner/dist/loader/css/react-spinner-loader.css';
@import '~antd/dist/antd.css';
@import './variables.scss';
@import './antd.scss';

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/montserrat/Montserrat-Regular.ttf') format('ttf');
}

@font-face {
	font-family: 'Hind Siliguri', sans-serif;
	src: url('./fonts/hind_siliguri/HindSiliguri-Regular.ttf') format('ttf');
}

body {
	// font-family: 'Montserrat', sans-serif !important;
	font-family: 'Hind Siliguri', sans-serif !important;
	font-size: 13px;
	background-color: #f5f8fa;

	.mh-300 {
		min-height: 300px;
	}

	.page-overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: $menu-width;
		background-color: rgba(255, 255, 255, 0.4);
		display: none;
		z-index: 1001;
	}

	&.page-menu-hidden {
		.page-overlay {
			left: 20px;
		}
	}

	.page-loader {
		position: fixed;
		left: calc(50% + (#{$menu-width} / 2));
		top: 50%;
		transform: translate(-50%, -50%);
		display: none;
		z-index: 1001;

		svg {
			fill: $color-primary !important;
		}
	}

	&.loading {
		.page-loader,
		.page-overlay {
			display: block;
		}
	}

	&.loading.initial {
		.page-loader {
			left: 50%;
		}
	}

	.btn {
		border-radius: 0;
		border: none;
		text-transform: uppercase;
		font-size: 0.75rem;
		font-weight: bold;

		&.btn-primary,
		&.btn-primary:active,
		&.btn-primary:focus {
			background-color: $color-primary !important;
			border-radius: 4px;
		}

		&.btn-secondary,
		&.btn-secondary:active,
		&.btn-secondary:focus {
			background-color: $color-secondary !important;
			border-radius: 4px;
		}

		&:hover {
			opacity: 0.8 !important;
		}

		&:disabled {
			cursor: not-allowed;

			&:hover {
				opacity: 1 !important;
			}
		}
		&.small {
			font-size: 0.6rem;
			padding-top: 5px;
		}
	}

	.list-style-none {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.special-icon {
		font-size: 1.2rem;
		cursor: pointer;
		color: #999;

		&:hover {
			color: black;
		}
	}

	input[type='text'].form-control,
	input[type='password'].form-control {
		border-color: $border-color;
		border-radius: 8px;
	}

	.kothing-editor-editable * {
		font-family: 'Montserrat', sans-serif !important;
	}

	.kothing-editor .ke-resizing-bar.ke-resizing-none {
		display: none;
	}

	.kothing-editor {
		border-color: $border-color;
		border-radius: 8px !important;
	}

	.kothing-editor .ke-toolbar {
		border-radius: 8px 8px 0 0;
	}

	.kothing-editor .ke-wrapper {
		border-radius: 8px;
	}
}
