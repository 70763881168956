// $color-primary: #bd2e2a;
$color-primary: #2c5c65;
$color-secondary: #888;
$border-color: #7c858c;
// $active-link-background: rgba(0, 0, 0, 0.4);
$active-link-background: #4999a9;
$menu-width: 215px;
$header-height: 55px;
$footer-height: 30px;
$menu-link-border: 1px solid rgba(255, 255, 255, 0.5);
$tagRCcolor: #f7b6d2;
$tagSLcolor: #98df8a;
$tagTHcolor: #b7b6f7;
$tagCTcolor: #cccccc;
