@import './variables.scss';

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: $border-color;
	border-radius: 8px;
}
.ant-select-arrow {
	color: $border-color;
}
.ant-select-clear {
	top: 14px;
}

.ant-table-thead > tr > th {
	font-weight: bold;
}

.ant-btn {
	border-radius: 4px;
	border: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: bold;
	color: white;
	background-color: $color-secondary !important;

	&.ant-btn-primary,
	&.ant-btn-primary:active,
	&.ant-btn-primary:focus {
		background-color: $color-primary !important;
	}

	&:hover {
		color: white;
		opacity: 0.8;
	}
}
.ant-picker {
	border-color: $border-color;
	border-radius: 8px;
}

.ant-input {
	border-color: $border-color;
	border-radius: 8px;
}

.ant-form-item-explain {
	font-size: 0.8rem;
}

// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
// 	color: $color-primary;
// }

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
	color: rgba(255, 255, 255, 0.5);
}

.ant-select-tree-list-holder {
	overflow-y: auto !important;
}
.ant-select-tree-list-scrollbar {
	display: none !important;
}

.taxonomy-reg-fa-lis .ant-select-selection-overflow-item .ant-select-selection-item {
	display: none;
}

.ant-input-affix-wrapper {
	border-radius: 4px;
}
